import currencyFormatter from 'currency-formatter';
import { EventEmitter, ListenerFn } from 'eventemitter3';
import 'swiper/dist/css/swiper.css';
import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import AppVue from './App.vue';
import { EVENTS, POPUP_NAMES } from './config/constants';
import { IEvents } from './config/contracts/IEvents';
import { IPopup } from './config/contracts/IPopup';
import { IScaleOptions } from './config/contracts/IScaleOptions';
import CommonEventEmitter from './event-emitter/CommonEventEmitter';
import globalMixin from './mixins/GlobalMixin.vue';
import store from './store';
import { IPaytable } from './store/modules/common/contracts/paytable';
import { IStakeLimits } from './store/modules/common/contracts/stake';
import { IPromotionalFreeSpinsState } from './store/modules/slot/contracts/promotionalFreeSpins';
import { EventBus } from './utils/EventBus';

Vue.config.productionTip = false;

export default class App extends EventEmitter {
    private vue: Vue;
    private events: IEvents = EVENTS;
    private _popupNames: IPopup = POPUP_NAMES;
    private _canClickDocument: boolean = true;

    constructor (loginConfig: any) {
        super();

        const { realmoney, currencyShow, beyondPlayIntegration } = loginConfig;

        Vue.filter('currencyFormat', (value: number) => {
            if (isNaN(value)) {
                return '';
            }

            const currencyOptions = (store.state as any).locale.currencyOptions;
            return currencyFormatter.format(value, Object.assign({
                code: (store.state as any).locale.currency,
                precision: currencyOptions ? currencyOptions.decimalPlaces : 2
            }, !realmoney && currencyShow === 'FUN' ? {
                symbol: currencyShow
            } : {}));
        });

        Vue.filter('paytableCurrencyFormat', (value: number) => {
            if (isNaN(value)) {
                return '';
            }

            return currencyFormatter.format(value.toString(), Object.assign({
                code: (store.state as any).locale.currency
            }, !realmoney && currencyShow === 'FUN' ? {
                symbol: currencyShow
            } : {}));
        });

        Vue.filter('lessThanBalance', (arr: Array<any>) => {
            let newArr: any[] = [];
            arr.forEach((item: any) => {
                if (item.value <= this.balance / 100) {
                    newArr.push(item);
                }
            });
            return newArr;
        });

        Vue.filter('minutesToDate', (value: number) => {
            if (isNaN(value)) {
                return '';
            }

            let dateObj, hours, minutes, seconds, timeString;

            dateObj = new Date(value * 1000 * 60);
            hours = dateObj.getUTCHours();
            minutes = dateObj.getUTCMinutes();
            seconds = dateObj.getSeconds();

            timeString = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
            return timeString;
        });

        Vue.use(VueAwesomeSwiper);

        Vue.prototype.$beyondPlay = beyondPlayIntegration;

        this.vue = new Vue({
            mixins: [globalMixin],
            store,
            render: h => h(AppVue)
        }).$mount('#app');

        this.initEvents();
        document.addEventListener('click', this.onDocumentClicked.bind(this));
    }

    public getEvents (): IEvents {
        return { ...this.events };
    }

    private initEvents (): void {
        for (let eventKey in this.events) {
            const event: string = this.events[eventKey];
            EventBus.$on(event, (context: any) => {
                this.emit(event, context);
            });
        }
    }

    protected showHardError (errorCode?: string) {
        const initialLoader = document.getElementById('initial-loader');
        if (initialLoader) {
            initialLoader.style.display = 'none';
        }

        this.vue.$store.dispatch('showHardError', errorCode);
    }

    protected setPromotionalFreeSpins (promotionalFreeSpins: IPromotionalFreeSpinsState) {
        this.vue.$store.dispatch('promotionalFreeSpins/setFreeSpins', promotionalFreeSpins);
    }

    protected setPromotionalFreeSpinsTotalWin (promotionalFreeSpinsTotalWin: number) {
        this.vue.$store.dispatch('promotionalFreeSpins/setTotalWin', promotionalFreeSpinsTotalWin);
    }

    protected appendGameUI () {
        this.vue.$store.dispatch('appendGameUI');
        this.vue.$store.dispatch('spinButton/setOpacity', true);
    }

    protected makeSpinButtonDisable () {
        this.vue.$store.dispatch('spinButton/makeSpinButtonDisable');
        // this.vue.$store.dispatch('spinButton/showStopButton');
    }

    protected makeSpinButtonActive () {
        this.vue.$store.dispatch('spinButton/makeSpinButtonActive');
        this.vue.$store.dispatch('spinButton/setOpacity', false);
    }

    protected disableHeaderControls () {
        this.vue.$store.dispatch('setHeaderControlsIsActive', false);
    }

    protected enableHeaderControls () {
        this.vue.$store.dispatch('setHeaderControlsIsActive', true);
    }

    protected toggleFSPlayButton (value: boolean) {
        this.vue.$store.dispatch('toggleFSPlayButton', value);
    }

    protected disableControls () {
        this.vue.$store.dispatch('disableControls');
    }

    protected enableControls () {
        this.vue.$store.dispatch('spinButton/hideStopButton');
        this.vue.$store.dispatch('enableControls');
    }

    protected updateBalance (balanceValue: number) {
        this.vue.$store.dispatch('balance/setValue', balanceValue);
    }

    protected setPaytable (paytable: IPaytable) {
        this.vue.$store.dispatch('paytable/setPaytable', paytable);
        this.vue.$store.dispatch('rules/setRules', paytable);
    }
    protected togglePaytable () {
        if (this.paytableIsActive) {
            this.vue.$store.dispatch('paytable/hidePaytable');
        } else {
            this.vue.$store.dispatch('rules/hideRules');
            this.vue.$store.dispatch('paytable/showPaytable');
        }
    }
    protected toggleHelp () {
        if (this.rulesIsActive) {
            this.vue.$store.dispatch('rules/hideRules');
        } else {
            this.vue.$store.dispatch('paytable/hidePaytable');
            this.vue.$store.dispatch('rules/showRules');
        }
    }
    protected setAutoplaySpinsLeft (spinsLeft: number): void {
        this.vue.$store.dispatch('autoplay/setSpinsLeft', spinsLeft);
    }

    protected setAutoplayRounds (autoplayRounds: number[]): void {
        this.vue.$store.dispatch('autoplay/setRounds', autoplayRounds);
    }

    protected setAutoplayDisable (autoplayDisable: boolean): void {
        this.vue.$store.dispatch('autoplay/setDisable', autoplayDisable);
    }

    protected startAutoplay (): void {
        this.vue.$store.dispatch('autoplay/setActive', true);
    }

    protected stopAutoplay (): void {
        this.vue.$store.dispatch('autoplay/setActive', false);
    }

    protected setTurboDisable (turboDisable: boolean): void {
        this.vue.$store.dispatch('turboSpinButton/showTurboSpinButton', turboDisable);
    }
    protected setStakeLimits (limits: IStakeLimits): void {
        // const betValueFromLocaleStorage = window.localStorage.getItem('ba');
        // if (betValueFromLocaleStorage && betValueFromLocaleStorage.length) {
        //     const betValue = +betValueFromLocaleStorage;
        //     if (!isNaN(betValue)) {
        //         cLimits.defaultBet = betValue;
        //     }
        // }

        this.vue.$store.dispatch('stake/setLimits', limits);
        CommonEventEmitter.announceStakeChanged(limits.defaultBet, this.vue.$options.filters!.currencyFormat(limits.defaultBet / 100));
    }

    protected updateStake (value: number) {
        this.vue.$store.dispatch('stake/setValue', value);
    }

    protected setCurrency (currency: string) {
        this.vue.$store.dispatch('locale/setCurrency', currency);
    }

    protected setValueToCurrencyCode (value: boolean) {
        this.vue.$store.dispatch('setCurrenyCodeValue', value);
    }

    protected onDocumentClicked () {
        if (!this._canClickDocument) return false;
        this._canClickDocument = false;
        setTimeout(() => {
            this._canClickDocument = true;
        }, 500);
        CommonEventEmitter.announceForceStoppingAnimations();
    }

    protected requestConfirm () {
        if (this.vue.$store.state.LoadingScreenIsShown) {
            CommonEventEmitter.requestConfirm();
        }
    }

    protected requestSkipIntro () {
        if (this.vue.$store.state.LoadingScreenIsShown) {
            CommonEventEmitter.requestConfirm();
        }
    }

    protected onResourcesReady () {
        CommonEventEmitter.announceResourcesReady();
    }

    protected historyDataReceived (data: string) {
        CommonEventEmitter.announceHistoryRecieved(data);
    }
    protected FEIMSoundStopping (mute: boolean) {
        CommonEventEmitter.announceFEIMSoundSet(mute);
    }
    protected FEIMTurboStopping (enable: boolean) {
        CommonEventEmitter.announceFEIMTurboSet(enable);
    }
    protected onGameResourcesProgress (percent: number) {
        CommonEventEmitter.announceProgressOfLoadingOfResources(percent);
    }

    protected onGameResize (scaleOptions: IScaleOptions) {
        CommonEventEmitter.announceWindowResizing(scaleOptions);
    }

    protected onHideFooter () {
        CommonEventEmitter.announceFooterHiding();
        this.makeSpinButtonDisable();
    }

    protected onShowFooter () {
        CommonEventEmitter.announceFooterShowing();
        this.makeSpinButtonActive();
    }

    protected onGameCanvasAppended () {
        CommonEventEmitter.announceGameCanvasReady();
    }

    protected showPopup (name: string, data?: any) {
        switch (name) {
        case POPUP_NAMES.PROMOTIONAL_FREE_SPINS:
            this.vue.$store.dispatch('popup/showPromotionalFreeSpinsPopup');
            break;
        case POPUP_NAMES.INFORMATIONAL:
            this.vue.$store.dispatch('popup/showInformationalPopup', data);
            break;
        case POPUP_NAMES.ERROR:
            this.vue.$store.dispatch('popup/showErrorPopup', data);
            break;
        case POPUP_NAMES.REALITY_CHECK:
            this.vue.$store.dispatch('popup/showRealityCheckPopup', data);
            break;
        case POPUP_NAMES.GAME_UNFINISHED:
            this.vue.$store.dispatch('popup/showGameUnfinishedPopup');
            break;
        }
    }

    protected hidePopup (name: string) {
        switch (name) {
        case POPUP_NAMES.PROMOTIONAL_FREE_SPINS:
            this.vue.$store.dispatch('popup/hidePromotionalFreeSpinsPopup');
            break;
        case POPUP_NAMES.INFORMATIONAL:
            this.vue.$store.dispatch('popup/hideInformationalPopup');
            break;
        case POPUP_NAMES.ERROR:
            this.vue.$store.dispatch('popup/hideErrorPopup');
            break;
        case POPUP_NAMES.GAME_UNFINISHED:
            this.vue.$store.dispatch('popup/hideGameUnfinishedPopup');
            break;
        case POPUP_NAMES.REALITY_CHECK:
            this.vue.$store.dispatch('popup/hideRealityCheckPopup');
            break;
        }
    }

    get balance () {
        return this.vue.$store.state.balance.value;
    }

    get popupNames (): IPopup {
        return this._popupNames;
    }
    get paytableIsActive (): boolean {
        return this.vue.$store.state.paytable.isActive;
    }

    get rulesIsActive (): boolean {
        return this.vue.$store.state.rules.isActive;
    }

    on<T extends string | symbol> (event: T, fn: ListenerFn<any[]>, context?: any): this {
        return super.on(event, fn, context);
    }
}

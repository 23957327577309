
import { Component, Prop, Mixins } from 'vue-property-decorator';

import { EventBus } from './../../../utils/EventBus';
import { EVENTS } from '../../../config/constants';
import GlobalMixin from '../../../mixins/GlobalMixin.vue';
import CommonEventEmitter from '../../../event-emitter/CommonEventEmitter';

@Component({
    components: {}
})
export default class RealityCheck extends Mixins(GlobalMixin) {
    wid: string = window.deviceInfo.isMobile ? '60%' : '46%';
    @Prop({ default: '' })
    realityData!: any;
    message: string = `./assets/${window.lang}/errors/texts/error_${this.realityData.code}.png`;
    continuePlaying: string = `./assets/${window.lang}/realitycheck/texts/realityCheck_confirm.png`;
    closeGame: string = `./assets/${window.lang}/realitycheck/texts/realityCheck_cancel.png`;
    history: string = `./assets/${window.lang}/realitycheck/texts/realityCheck_history.png`;

    messagePlayingFor: string = `./assets/${window.lang}/realitycheck/texts/realityCheck_message_1.png`;
    messageWagered: string = `./assets/${window.lang}/realitycheck/texts/realityCheck_message_3.png`;
    messageWon: string = `./assets/${window.lang}/realitycheck/texts/realityCheck_message_4.png`;
    formatter: string = '(HH:mm:ss)';

    mounted () {
        this.$nextTick(() => {
            if (this.realityData.status === 'ok' && this.realityData.rcinterval) {
                // this.messagePlayingFor =
            }
        });
    }

    handleRealityCheckPopup () {
        this.$store.dispatch('popup/hideRealityCheckPopup');
        CommonEventEmitter.announceContinuePlaying();
        this.playSound('ClickButtons');
    }
    handleRealityCheckPopupHistory () {
        if (this.realityData.rchistoryurl.indexOf('action:') === -1) {
            window.location.href = this.realityData.rchistoryurl;
        } else {
            // this event is for post message API inside iframe
            CommonEventEmitter.announceUserActionPostMessage(this.realityData.rchistoryurl);
        }
    }

    handleCloseGame () {
        // location.reload();
        this.playSound('ClickButtons');
    }
}


import { Component, Prop, Mixins } from 'vue-property-decorator';

import { EventBus } from './../../../utils/EventBus';
import { EVENTS } from '../../../config/constants';
import GlobalMixin from '../../../mixins/GlobalMixin.vue';

@Component({
    components: {}
})
export default class Error extends Mixins(GlobalMixin) {
    @Prop({ default: '' })
    content!: string;

    errorImage: string = `./assets/${window.lang}/errors/texts/error_${this.content}.png`;
    continueBtn: string = `./assets/${window.lang}/ui/texts/ui_continue.png`;
    closeGame: string = `./assets/${window.lang}/realitycheck/texts/realityCheck_cancel.png`;
    wid: string = window.deviceInfo.isMobile ? '60%' : '46%';

    mounted () {
        this.$beyondPlay.events.once('confirm', this.handleClose);
        this.$beyondPlay.confirmReady();
    }

    beforeDestroy () {
        this.$beyondPlay.events.off('confirm', this.handleClose);
    }

    handleClose () {
        if (this.content === '506') {
            this.$store.dispatch('popup/hideErrorPopup');
        } else {
            window.location.reload();
        }
    }

    handleErrorPopup () {
        this.handleClose();
        this.playSound('ClickButtons');
    }

    reloadGame () {
        window.location.reload();
        this.playSound('ClickButtons');
    }
}
